import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import router from './router/index'
import store from '@/state/store'
import vco from "v-click-outside"
import axios from 'axios'
import "@/design/index.scss";
import Sparkline from 'vue-sparklines'

Vue.use(BootstrapVue);
Vue.config.productionTip = false
Vue.use(Vuelidate);
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(require('vue-chartist'))
Vue.use(vco)
Vue.use(Sparkline)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)

axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL;
axios.defaults.headers['Access-Control-Allow-Origin'] = '*';

new Vue({
  router,
  store,
  created () {
    axios.interceptors.response.use((response) => {
      return response
    }, (error) => { 
      if(error?.response?.status === 401 && !JSON.parse(localStorage.getItem('user'))) {
          router.push('/login');
      }
      if(error?.response?.status === 403) {
          //this.$router.push('/');
      }
      throw new Error(error.response.data.message);
    }),
    Vue.prototype.$tenant = process.env.VUE_APP_TENANT_CODE;
  },
  render: h => h(App),
}).$mount('#app')