export const reportsRoutes = [      
    {
        path: '/reports/sales',
        name: 'Relatorio de Vendas',
        component: () => import('../views/reports/sales'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/reports/enrols',
        name: 'Relatorio de Matrículas',
        component: () => import('../views/reports/enrols/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/reports/contracts',
        name: 'Relatorio de Matrículas em Planos',
        component: () => import('../views/reports/contracts/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/reports/quality',
        name: 'Relatorio de Qualidade',
        component: () => import('../views/reports/quality/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/reports/certificates',
        name: 'Relatorio de Certificados',
        component: () => import('../views/reports/certificates/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/reports/exports',
        name: 'Exportar Relatórios',
        component: () => import('../views/reports/exports/index'),
        meta: {
            authRequired: true,
        },
    }, 
]