import { skillService } from '../../helpers/services/skill.service';

export const state = {
    skills: [],
}

export const mutations = {
    success(state, skills) {
        state.type = 'alert-success';
        state.skills = skills;
    },
    skillsFailure(state, error) {
        state.type = 'alert-danger';
        state.error = error;
    },
    saveSuccess(state, skill) {
        state.type = 'alert-success';
        state.skill = skill;
    },
    destroySuccess(state, msg) {
        state.type = 'alert-success';
        state.msg = msg;
    },
};

export const actions = {
    async getAllSkill({ dispatch, commit }, formSearch = null) {
        return await skillService.index(formSearch)
            .then(
                skills => {
                    commit('success', skills);
                    return skills;
                },
                error => {
                    commit('skillsFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    },
    async saveSkill({ dispatch, commit }, skill) {
        return await skillService.save(skill)
            .then(
                skill => {
                    commit('saveSuccess', skill);
                    dispatch('notification/success', 'habilidade salva com sucesso!', { root: true });
                    return skill;
                },
                error => {
                    commit('skillsFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    },
    async updateSkill({ dispatch, commit }, skill) {
        return await skillService.update(skill)
            .then(
                skill => {
                    commit('saveSuccess', skill);
                    dispatch('notification/success', 'habilidade salva com sucesso!', { root: true });
                    return skill;
                },
                error => {
                    commit('skillsFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    },
    async deleteSkill({ dispatch, commit }, skillId) {
        return await skillService.destroy(skillId)
            .then(
                msg => {
                    commit('destroySuccess', msg);
                    dispatch('notification/success', 'habilidade excluida com sucesso!', { root: true });
                    return msg;
                },
                error => {
                    commit('skillsFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    }
};
