export const contentRoutes = [
    {
        path: '/authors',
        name: 'Autores',
        component: () => import('../views/content/authors/index'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/author/create',
        name: 'Cadastro Autor',
        component: () => import('../views/content/authors/author-form'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/author/edit/:id',
        name: 'Editar Autor',
        component: () => import('../views/content/authors/author-form'),
        meta: {
            authRequired: true,
        },
        props: true,
    },
    {
        path: '/categories',
        name: 'Categorias',
        component: () => import('../views/content/categories/index'),
        meta: {
            authRequired: true,
        }
    },
    
    {
        path: '/category/create',
        name: 'Cadastro Categoria',
        component: () => import('../views/content/categories/category-form'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/category/edit/:id',
        name: 'Editar Categoria',
        component: () => import('../views/content/categories/category-form'),
        meta: {
            authRequired: true,
        },
        props: true,
    },
    {
        path: '/courses',
        name: 'Cursos',
        component: () => import('../views/content/courses/index'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/course/create',
        name: 'Cadastro Curso',
        component: () => import('../views/content/courses/course-form'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/course/edit/:id',
        name: 'Editar Curso',
        component: () => import('../views/content/courses/course-form'),
        meta: {
            authRequired: true,
        },
        props: true,
    },
    {
        path: '/live-classes',
        name: 'Aulas ao vivo',
        component: () => import('../views/content/live-classes/index'),
        meta: {
            authRequired: true,
        },
    },    
    {
        path: '/pedagogical/skills',
        name: 'Habilidades',
        component: () => import('../views/content/skills/index'),
        meta: {
            authRequired: true,
        }
    },
]