export const messagingRoutes = [
    {
        path: '/messaging/emails',
        name: 'Emails',
        component: () => import('../views/comunication/emails/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/messaging/communities',
        name: 'Comunidades',
        component: () => import('../views/comunication/communities/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/messaging/warnings',
        name: 'Quadro de Avisos',
        component: () => import('../views/comunication/warnings/index'),
        meta: {
            authRequired: true,
        },
    },
]