import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

import { affiliatedRoutes } from './routes/affiliated-routes';
import { contentRoutes } from './routes/content-routes';
import { defaultRoutes } from './routes/default-routes';
import { gameficationRoutes } from './routes/gamefication-routes';
import { messagingRoutes } from './routes/messaging-routes';
import { reportsRoutes } from './routes/reports-routes';
import { settingsRoutes } from './routes/settings-routes';
import { studentRoutes } from './routes/student-routes';
import { tutoringRoutes } from './routes/tutoring-routes';


export default [
    ...affiliatedRoutes,
    ...contentRoutes,
    ...defaultRoutes,
    ...gameficationRoutes,
    ...messagingRoutes,
    ...reportsRoutes,
    ...settingsRoutes,
    ...studentRoutes,
    ...tutoringRoutes,
    {
        path: '/plans',
        name: 'Planos e Combos',
        component: () => import('./views/plans-combos/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/groups',
        name: 'Grupos',
        component: () => import('./views/groups/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/manage-admins',
        name: 'Gerenciar Admins',
        component: () => import('./views/manage-accounts/admins/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/manage-admins/create',
        name: 'Adicionar Admin',
        component: () => import('./views/manage-accounts/admins/admin-form'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/manage-profile',
        name: 'Gerenciar Perfil',
        component: () => import('./views/manage-accounts/manage-profile/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/client-plans/home',
        name: 'Planos do Sistema',
        component: () => import('./views/client-plans/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/pages/blank-page',
        name: 'Blank page',
        component: () => import('./views/pages/blank'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/pages/404',
        name: 'Page-404',
        component: () => import('./views/pages/error-404'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/pages/500',
        name: 'Page-500',
        component: () => import('./views/pages/error-500'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/pages/maintenance',
        name: 'Maintenance',
        component: () => import('./views/pages/maintenance'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/pages/lock-screen1',
        name: 'Lock-screen 1',
        component: () => import('./views/pages/lock-screen1'),
        meta: {
            authRequired: true,
        }
    },
];