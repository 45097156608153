import { liveService } from '../../helpers/services/live.service';

export const state = {
    lives: [],
}

export const mutations = {
    success(state, lives) {
        state.type = 'alert-success';
        state.lives = lives;
    },
    livesFailure(state, error) {
        state.type = 'alert-danger';
        state.error = error;
    },
    saveSuccess(state, live) {
        state.type = 'alert-success';
        state.live = live;
    },
    destroySuccess(state, msg) {
        state.type = 'alert-success';
        state.msg = msg;
    },
};

export const actions = {
    async getAllLive({ dispatch, commit }, formSearch = null) {
        return await liveService.index(formSearch)
            .then(
                lives => {
                    commit('success', lives);
                    return lives;
                },
                error => {
                    commit('livesFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    },
    async saveLive({ dispatch, commit }, live) {
        return await liveService.save(live)
            .then(
                live => {
                    commit('saveSuccess', live);
                    dispatch('notification/success', 'live salva com sucesso!', { root: true });
                    return live;
                },
                error => {
                    commit('livesFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    },
    async update({ dispatch, commit }, live) {
        return await liveService.update(live)
            .then(
                live => {
                    commit('saveSuccess', live);
                    dispatch('notification/success', 'live salva com sucesso!', { root: true });
                    return live;
                },
                error => {
                    commit('livesFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    },
    async deleteLive({ dispatch, commit }, liveId) {
        return await liveService.destroy(liveId)
            .then(
                msg => {
                    commit('destroySuccess', msg);
                    dispatch('notification/success', 'live excluida com sucesso!', { root: true });
                    return msg;
                },
                error => {
                    commit('livesFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    }
};
