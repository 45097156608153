export const tutoringRoutes = [
    {
        path: '/pedagogical/reviews',
        name: 'Avaliações',
        component: () => import('../views/tutoring/reviews/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/pedagogical/student-doubts',
        name: 'Mensagens',
        component: () => import('../views/tutoring/student-doubts/index'),
        meta: {
            authRequired: true,
        },
    }
]