import { moduleService } from '../../helpers/services/module.service';

export const state = {
    modules: [],
}

export const mutations = {
    success(state, modules) {
        state.type = 'alert-success';
        state.modules = modules;
    },
    modulesFailure(state, error) {
        state.type = 'alert-danger';
        state.error = error;
    },
    saveSuccess(state, module) {
        state.type = 'alert-success';
        state.module = module;
    },
    destroySuccess(state, msg) {
        state.type = 'alert-success';
        state.msg = msg;
    },
};

export const actions = {
    async getAllModule({ dispatch, commit }, formSearch = null) {
        return await moduleService.index(formSearch)
            .then(
                modules => {
                    commit('success', modules);
                    return modules;
                },
                error => {
                    commit('modulesFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    },
    async saveModule({ dispatch, commit }, module) {
        return await moduleService.save(module)
            .then(
                module => {
                    commit('saveSuccess', module);
                    dispatch('notification/success', 'Módulo salvo com sucesso!', { root: true });
                    return module;
                },
                error => {
                    commit('modulesFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    },
    async updateModule({ dispatch, commit }, module) {
        return await moduleService.update(module)
            .then(
                module => {
                    commit('saveSuccess', module);
                    dispatch('notification/success', 'Módulo salvo com sucesso!', { root: true });
                    return module;
                },
                error => {
                    commit('modulesFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    },
    async deleteModule({ dispatch, commit }, moduleId) {
        return await moduleService.destroy(moduleId)
            .then(
                msg => {
                    commit('destroySuccess', msg);
                    dispatch('notification/success', 'Módulo excluida com sucesso!', { root: true });
                    return msg;
                },
                error => {
                    commit('modulesFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    }
};
