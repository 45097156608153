export const studentRoutes = [      
    {
        path: '/students',
        name: 'Alunos',
        component: () => import('../views/students/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/enrol_student',
        name: 'Matricular Aluno',
        component: () => import('../views/students/student-form'),
        meta: {
            authRequired: true,
        },
    },  
]