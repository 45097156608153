import { authHeader } from './auth-header';
import axios from 'axios'

export const userService = {
    login,
    logout,
    getAll,
};

function login(email, password) {
    const user = { email, password };
    const headers = authHeader();
  
    return axios
        .post(`login`, user, { headers })
        .then((response) => {
            const data = response.data;
    
            if (data.authorization.token) {
                localStorage.setItem('user', JSON.stringify(data.user));
                localStorage.setItem('token', data.authorization.token);
            }
    
            return data;
        }, (error) => {
            return handleError(error);
        });
}

async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    try {
        const response = await fetch(`/users`, requestOptions);
        return handleResponse(response);
    } catch (error) {
        throw handleError(error);
    }
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('token');
}


function handleResponse(response) {
    return response.json().then(data => {
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data.user;
    });
}

function handleError(error) {
    if (error?.response) {
        return Promise.reject(error.response.data.message);
    } else if (error?.request) {
        return Promise.reject('Sem resposta do servidor.');
    } else {
        return Promise.reject(error?.message);
    }
}