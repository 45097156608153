import { courseService } from '../../helpers/services/course.service';

export const state = {
    courses: [],
}

export const mutations = {
    success(state, courses) {
        state.type = 'alert-success';
        state.courses = courses;
    },
    failure(state, error) {
        state.type = 'alert-danger';
        state.error = error;
    },
    saveSuccess(state, course) {
        state.type = 'alert-success';
        state.course = course;
    },
    destroySuccess(state, msg) {
        state.type = 'alert-success';
        state.msg = msg;
    },
};

export const actions = {
    async getAllCourse({ dispatch, commit }, formSearch = null) {
        return await courseService.index(formSearch)
            .then(
                courses => {
                    commit('success', courses);
                    return courses;
                },
                error => {
                    commit('failure', error);
                    dispatch('notification/error', error, { root: true });
                    return null
                }
            );
    },
    async getCourse({ dispatch, commit }, courseId) {
        return await courseService.get(courseId)
            .then(
                course => {
                    commit('success', course);
                    return course;
                },
                error => {
                    commit('courseFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return null
                }
            );
    },
    async saveCourse({ dispatch, commit }, course) {
        await courseService.save(course)
            .then(
                course => {
                    commit('saveSuccess', course);
                    dispatch('notification/success', 'Curso salvo com sucesso!', { root: true });
                },
                error => {
                    commit('courseFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    async deleteCourse({ dispatch, commit }, planId) {
        await courseService.destroy(planId)
            .then(
                msg => {
                    commit('destroySuccess', msg);
                    dispatch('notification/success', 'Curso excluido com sucesso!', { root: true });
                    return msg;
                },
                error => {
                    commit('courseFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    }
};
