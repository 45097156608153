import { authorService } from '../../helpers/services/author.service';

export const state = {
    authors: [],
}

export const mutations = {
    success(state, authors) {
        state.type = 'alert-success';
        state.authors = authors;
    },
    authorsFailure(state, error) {
        state.type = 'alert-danger';
        state.error = error;
    },
    saveSuccess(state, author) {
        state.type = 'alert-success';
        state.author = author;
    },
    destroySuccess(state, msg) {
        state.type = 'alert-success';
        state.msg = msg;
    },
};

export const actions = {
    async getAllAuthor({ dispatch, commit }, formSearch = null) {
        return await authorService.index(formSearch)
            .then(
                authors => {
                    commit('success', authors);
                    return authors;
                },
                error => {
                    commit('authorsFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return null
                }
            );
    },
    async getAuthor({ dispatch, commit }, authorId) {
        return await authorService.get(authorId)
            .then(
                author => {
                    commit('success', author);
                    return author;
                },
                error => {
                    commit('authorsFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return null
                }
            );
    },
    async saveAuthor({ dispatch, commit }, author) {
        return await authorService.save(author)
            .then(
                author => {
                    commit('saveSuccess', author);
                    dispatch('notification/success', 'Autor salvo com sucesso!', { root: true });
                    return author;
                },
                error => {
                    commit('authorsFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error;
                }
            );
    },
    async deleteAuthor({ dispatch, commit }, authorId) {
        return await authorService.destroy(authorId)
            .then(
                msg => {
                    commit('destroySuccess', msg);
                    dispatch('notification/success', 'Autor excluido com sucesso!', { root: true });
                    return msg;
                },
                error => {
                    commit('authorsFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error;
                }
            );
    }
};
