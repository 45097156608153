export const gameficationRoutes = [      
    {
        path: '/gamefication/ranking',
        name: 'Gameficação Ranking',
        component: () => import('../views/gamefication/ranking/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/gamefication/shields',
        name: 'Gameficação Escudos',
        component: () => import('../views/gamefication/shields/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/gamefication/triggers-points',
        name: 'Gameficação Pontuação',
        component: () => import('../views/gamefication/points/index'),
        meta: {
            authRequired: true,
        },
    }  
]