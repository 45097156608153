import { authHeader } from '../authservice/auth-header';
import axios from 'axios'
import {
    defineSearch,
} from "@/state/helpers";


const headers = authHeader();

export const baseCrudService = {
    recurso: '',
    id: null,
    index,
    get,
    save,
    create,
    update,
    destroy,
};

async function index(search) {  
    search = search ? defineSearch(search) : '';  
    return axios
    .get(`${this.recurso}` + (this.id ? `/${this.id}` : '') + `${search}`, { headers })
    .then(response => {
        const data = response.data;    
        return data;
    })
    .catch(error => {
        return handleError(error);
    });
}

function get(id) {  
    return axios
        .get(`${this.recurso}/${id}`, { headers })
        .then(response => {
            const data = response.data;    
            return data;
        })
        .catch(error => {
            return handleError(error);
        });
}

async function save(model) {
    if(model?.id) {
        await this.update(model);
    } else {
        await this.create(model);
    }
}

async function create(model) {
    const data = model;
    try {
        const header = isFormData(model) ? authHeader(true) : headers
        const response = await axios.post(`${this.recurso}`, data, 
            { headers: header });
        return response.data;
    } catch (error) {
        throw handleError(error);
    }
}

async function update(model) {
    const data = model;
    try {
        const header = isFormData(model) ? authHeader(true) : headers
        const response = await axios.put(`${this.recurso}/${model.id}`, data, 
            { headers: header });
        return response.data;
    } catch (error) {
        throw handleError(error);
    }
}

function isFormData(model) {
    return model instanceof FormData;
}

async function destroy(id) {
    try {
        const response = await axios.delete(`${this.recurso}/${id}`, { headers });
        return response.data;
    } catch (error) {
        throw handleError(error);
    }
}

function handleError(error) {
    if (error.response) {
        return Promise.reject(error.response.data.message);
    } else if (error.request) {
        return Promise.reject('Sem resposta do servidor.');
    } else {
        return Promise.reject(error.message);
    }
}