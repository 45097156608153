import { categoryService } from '../../helpers/services/category.service';

export const state = {
    categories: [],
}

export const mutations = {
    success(state, categories) {
        state.type = 'alert-success';
        state.categories = categories;
    },
    categoriesFailure(state, error) {
        state.type = 'alert-danger';
        state.error = error;
    },
    saveSuccess(state, category) {
        state.type = 'alert-success';
        state.category = category;
    },
    destroySuccess(state, msg) {
        state.type = 'alert-success';
        state.msg = msg;
    },
};

export const actions = {
    async getAllCategory({ dispatch, commit }, formSearch = null) {
        return await categoryService.index(formSearch)
            .then(
                categories => {
                    commit('success', categories);
                    return categories;
                },
                error => {
                    commit('categoriesFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return null
                }
            );
    },
    async getCategory({ dispatch, commit }, categoryId) {
        return await categoryService.get(categoryId)
            .then(
                category => {
                    commit('success', category);
                    return category;
                },
                error => {
                    commit('categoriesFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return null
                }
            );
    },
    async saveCategory({ dispatch, commit }, category) {
        return await categoryService.save(category)
            .then(
                category => {
                    commit('saveSuccess', category);
                    dispatch('notification/success', 'Categoria salva com sucesso!', { root: true });
                    return category;
                },
                error => {
                    commit('categoriesFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error;
                }
            );
    },
    async deleteCategory({ dispatch, commit }, categoryId) {
        return await categoryService.destroy(categoryId)
            .then(
                msg => {
                    commit('destroySuccess', msg);
                    dispatch('notification/success', 'Categoria excluida com sucesso!', { root: true });
                    return msg;
                },
                error => {
                    commit('categoriesFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error;
                }
            );
    }
};
