export function authHeader(formData = false) {
    const header = {
        'Content-Type': formData ? 'multipart/form-data' : 'application/json',
        'Accept': 'application/x.fluxead.v1+json',
        'X-Tenant': '1'
    }
    const token = localStorage.getItem('token');
    if (token) {
        return { ...header, 'Authorization': 'Bearer ' + token };
    } else {
        return { ...header };
    }
}