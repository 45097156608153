export const affiliatedRoutes = [    
    {
        path: '/affiliateds',
        name: 'Afiliados',
        component: () => import('../views/affiliateds-and-coupons/affiliateds/index'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/coupons',
        name: 'Cupons',
        component: () => import('../views/affiliateds-and-coupons/coupons/index'),
        meta: {
            authRequired: true,
        }
    },
]