import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader
  }),
}

export function defineFormData(form) {
  const formData = new FormData();
  for (const key in form) {
    if (Object.hasOwnProperty.call(form, key)) {
      if (key !== 'created_at' && key !== 'updated_at' && key !== 'deleted_at') {
        formData.append(key, form[key]);
      }
    }
  }
  return formData;
}

export function defineSearch(formSearch) {
  let searchString = '?';
  for (const key in formSearch) {
    if (formSearch.hasOwnProperty(key) && formSearch[key]) {
      if (formSearch[key].hasOwnProperty('value')) {        
        searchString += `${key}=${formSearch[key].value}&`;
      } else {
        searchString += `${key}=${formSearch[key].hasOwnProperty('id') 
          ? formSearch[key].id 
          : formSearch[key]}&`;
      }      
    }
  }
  searchString = searchString.slice(0, -1);
  return searchString;
}

export function formatDateForInput(date) {
  var components = date.split('/');
  var newDate = new Date(components[2], components[1] - 1, components[0]);
  var day = newDate.getDate();
  var month = newDate.getMonth() + 1;
  var year = newDate.getFullYear();

  day = day < 10 ? '0' + day : day;
  month = month < 10 ? '0' + month : month;

  return year + '-' + month + '-' + day;
}

const defaultMethods = ['getAll', 'get', 'save', 'update', 'delete'];

function createExtendedMethods(moduleName, methods) {
  return methods.reduce((extendedMethods, method) => {
      extendedMethods[`${method}${moduleName}`] = `${method}${moduleName}`;
      return extendedMethods;
  }, {});
}

export const authMethods = mapActions('authfack', ['login', 'register', 'logout']);
export const authorMethods = mapActions('author', createExtendedMethods('Author', defaultMethods));
export const categoryMethods = mapActions('category', createExtendedMethods('Category', defaultMethods));
export const comboMethods = mapActions('combo', createExtendedMethods('Combo', defaultMethods));
export const courseMethods = mapActions('course', createExtendedMethods('Course', defaultMethods));
export const enrolMethods = mapActions('enrol', ['getAll', 'get', 'enrol', 'reEnrol', 'delete'])
export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue']);
export const liveMethods = mapActions('live', createExtendedMethods('Live', defaultMethods));
export const moduleMethods = mapActions('module', createExtendedMethods('Module', defaultMethods));
export const notificationMethods = mapActions('notification', ['success', 'error', 'clearNotifications']);
export const planMethods = mapActions('plan', createExtendedMethods('Plan', defaultMethods));
export const skillMethods = mapActions('skill', createExtendedMethods('Skill', defaultMethods));
