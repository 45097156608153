export const settingsRoutes = [      
    {
        path: '/settings/ava',
        name: 'Configurações AVA',
        component: () => import('../views/settings/ava-settings/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/settings/certificates',
        name: 'Configurações Certificados',
        component: () => import('../views/settings/certificates/index'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/settings/gateways-credentials',
        name: 'Credenciais de Gateways',
        component: () => import('../views/settings/gateways-settings/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/settings/common-questions',
        name: 'Perguntas Frequentes',
        component: () => import('../views/settings/common-questions/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/settings/pages',
        name: 'Páginas Extras',
        component: () => import('../views/settings/extra-pages/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/settings/system',
        name: 'Configurações do Sistema',
        component: () => import('../views/settings/system-settings/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/settings/smtp',
        name: 'Configurações de SMTP',
        component: () => import('../views/settings/smtp-settings/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/settings/frontend',
        name: 'Configurações do Site',
        component: () => import('../views/settings/frontend-settings/index'),
        meta: {
            authRequired: true,
        },
    } 
]