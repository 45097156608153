import { enrolService } from '../../helpers/services/enrol.service';

export const state = {
    enrols: [],
}

export const mutations = {
    success(state, enrols) {
        state.type = 'alert-success';
        state.enrols = enrols;
    },
    enrolsFailure(state, error) {
        state.type = 'alert-danger';
        state.error = error;
    },
    saveSuccess(state, enrol) {
        state.type = 'alert-success';
        state.enrol = enrol;
    },
};

export const actions = {
    async getAll({ dispatch, commit }) {
        return await enrolService.index()
            .then(
                enrols => {
                    commit('success', enrols);
                    return enrols;
                },
                error => {
                    commit('enrolsFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return null
                }
            );
    },
    save({ dispatch, commit }, enrol) {
        enrolService.save(enrol)
            .then(
                user => {
                    commit('saveSuccess', user);
                    dispatch('notification/success', 'Matrículas realizada com sucesso!', { root: true });
                },
                error => {
                    commit('enrolsFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    }
};
