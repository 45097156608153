import { comboService } from '../../helpers/services/combo.service';

export const state = {
    combos: [],
}

export const mutations = {
    success(state, combos) {
        state.type = 'alert-success';
        state.combos = combos;
    },
    combosFailure(state, error) {
        state.type = 'alert-danger';
        state.error = error;
    },
    saveSuccess(state, plan) {
        state.type = 'alert-success';
        state.plan = plan;
    },
    destroySuccess(state, msg) {
        state.type = 'alert-success';
        state.msg = msg;
    },
};

export const actions = {
    async getAllCombo({ dispatch, commit }, formSearch = null) {
        return await comboService.index(formSearch)
            .then(
                combos => {
                    commit('success', combos);
                    return combos;
                },
                error => {
                    commit('combosFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return null
                }
            );
    },
    saveCombo({ dispatch, commit }, plan) {
        comboService.save(plan)
            .then(
                plan => {
                    commit('saveSuccess', plan);
                    dispatch('notification/success', 'Combo salvo com sucesso!', { root: true });
                },
                error => {
                    commit('combosFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    deleteCombo({ dispatch, commit }, planId) {
        comboService.destroy(planId)
            .then(
                msg => {
                    commit('destroySuccess', msg);
                    dispatch('notification/success', 'Combo excluido com sucesso!', { root: true });
                    return msg;
                },
                error => {
                    commit('combosFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    }
};
