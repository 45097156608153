import { planService } from '../../helpers/services/plan.service';

export const state = {
    plans: [],
}

export const mutations = {
    success(state, plans) {
        state.type = 'alert-success';
        state.plans = plans;
    },
    plansFailure(state, error) {
        state.type = 'alert-danger';
        state.error = error;
    },
    saveSuccess(state, plan) {
        state.type = 'alert-success';
        state.plan = plan;
    },
    destroySuccess(state, msg) {
        state.type = 'alert-success';
        state.msg = msg;
    },
};

export const actions = {
    async getAllPlan({ dispatch, commit }, formSearch = null) {
        return await planService.index(formSearch)
            .then(
                plans => {
                    commit('success', plans);
                    return plans;
                },
                error => {
                    commit('plansFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return null
                }
            );
    },
    savePlan({ dispatch, commit }, plan) {
        planService.save(plan)
            .then(
                plan => {
                    commit('saveSuccess', plan);
                    dispatch('notification/success', 'Plano salvo com sucesso!', { root: true });
                },
                error => {
                    commit('plansFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    deletePlan({ dispatch, commit }, planId) {
        planService.destroy(planId)
            .then(
                msg => {
                    commit('destroySuccess', msg);
                    dispatch('notification/success', 'Plano excluido com sucesso!', { root: true });
                    return msg;
                },
                error => {
                    commit('plansFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    }
};
