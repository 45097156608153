import { sectionService } from '../../helpers/services/section.service';

export const state = {
    sections: [],
}

export const mutations = {
    success(state, sections) {
        state.type = 'alert-success';
        state.sections = sections;
    },
    sectionsFailure(state, error) {
        state.type = 'alert-danger';
        state.error = error;
    },
    saveSuccess(state, section) {
        state.type = 'alert-success';
        state.section = section;
    },
    destroySuccess(state, msg) {
        state.type = 'alert-success';
        state.msg = msg;
    },
};

export const actions = {
    async getAllSection({ dispatch, commit }) {
        return await sectionService.index()
            .then(
                sections => {
                    commit('success', sections);
                    return sections;
                },
                error => {
                    commit('sectionsFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    },
    async saveSection({ dispatch, commit }, section) {
        return await sectionService.save(section)
            .then(
                section => {
                    commit('saveSuccess', section);
                    dispatch('notification/success', 'Módulo salvo com sucesso!', { root: true });
                    return section;
                },
                error => {
                    commit('sectionsFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    },
    async updateSection({ dispatch, commit }, section) {
        return await sectionService.update(section)
            .then(
                section => {
                    commit('saveSuccess', section);
                    dispatch('notification/success', 'Módulo salvo com sucesso!', { root: true });
                    return section;
                },
                error => {
                    commit('sectionsFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    },
    async deleteSection({ dispatch, commit }, sectionId) {
        return await sectionService.destroy(sectionId)
            .then(
                msg => {
                    commit('destroySuccess', msg);
                    dispatch('notification/success', 'Módulo excluida com sucesso!', { root: true });
                    return msg;
                },
                error => {
                    commit('sectionsFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error
                }
            );
    }
};
